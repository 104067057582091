<template>
  <div>
    <Navbar page="Adequações em andamento" link="/entregaveis" nameLink="Entregáveis" link_2="/entregaveis/especifico" nameLink_2="Relatórios Específicos" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
        <h1 class="text-3xl mb-1">Relatório Específico</h1>
        <a v-if="link" :href="link" target="_blank" class="text-base text-underline text-blue-500">Visualizar relatório: {{ link}}</a>
        <div v-if="list && list.length" class="flex flex-col mt-12">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          </svg>
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Nome
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          CNPJ
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Cadastro
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="item in list" :key="item._id">
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm" id="titularmenor">
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              {{item.nome ? item.nome : ''}}
                            </div>
                          </div>
                        </td>
                        <td class="px-2 py-4">
                          <div class="flex items-center">
                            <div class="text-sm_2 text-gray-500">
                              {{item.cnpj ? item.cnpj : ''}}
                            </div>
                          </div>
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              {{item.data | moment("DD/MM/YYYY")}}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
              </div>
            </div>
        </div>

        <div v-if="selecionados && selecionados.length" class="grid grid-cols-12 gap-6 mt-4">
            <div class="col-span-12 md:col-span-4">
                <label for="titulo" class="block text-sm font-medium">
                    De um título para o relatório
                </label>
                <input v-model="form.titulo" type="text" name="titulo" id="titulo" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                <button @click="gerarRelatorio()" class="text-white bg-yellow-400 hover:bg-yellow-500 rounded py-1 px-5 mt-5">
                  Gerar Relatório
                </button>
            </div>
            <div class="col-span-12 md:col-span-6 bg-gray-100 py-4 px-4 rounded md:ml-6">
              <label class="block text-sm font-medium">
                  Empresas:
              </label>
              <div class="my-2" v-for="(item, idx) in selecionados" :key="idx">
                <span class="text-sm">{{ item.nome }}</span>
              </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            list: [],
            total: 0,
            page: 1,
            perPage: 20,
            selecionados: [],
            link: "",
            form: {
              titulo: ''
            },
            busca: { limit: this.perPage },
        }
    },
    methods: {
        async buscar(){
            this.busca = { limit: this.perPage };

            const listReq = await this.$http.post(`/v1/empresas/list`, this.busca);
            if(!listReq.data || !listReq.data.data.length) {
              this.list = [];
              this.total = 0;
              return this.$vToastify.error("Nenhuma atividade encontrada");
            }
            this.list = listReq.data.data;
            this.total = listReq.data.total;
            this.link = "";
        },

        async selecionarTodos () {
          for(let i = 0; i < this.list.length; i++){
            const item = this.list[i];
            if(this.selecionados.map(el => el._id).indexOf(item._id) ==! -1) this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
            else this.selecionados.push(item);
          }
        },

        async setPage(page) {
            this.page = page;
            const skip = (page - 1) * this.perPage;
            const limit = this.perPage;
            
            this.busca.skip = skip;
            this.busca.limit = limit;
            const listReq = await this.$http.post(`/v1/tarefas/list`, this.busca);
            this.list = listReq.data.data;
            this.total = listReq.data.total;
        },

        selecionadosControl(item, e) {
          if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
            this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
          } else {
            this.selecionados.push(item);
          }
        },

        async gerarRelatorio(){
          const req = await this.$http.post(`/v1/entregaveis/especifico/empresas`, { titulo: this.form.titulo, selecionados: this.selecionados.map(el => el._id) });
          window.open(`${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis`);
          this.link = `${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis`;
        },
    },
    async beforeMount() {
      this.buscar();
    }
}
</script>